<template>
  <div class="container add-country-button-component">
    <slot name="btn" :scoped="{ addLoading, showDialog }">
      <el-button type="primary" class="addButton" @click="showDialog">+ 添加{{ formworkName }}</el-button>
    </slot>
    <el-dialog
      append-to-body
      v-if="visible"
      :title="'添加' + formworkName"
      :visible.sync="visible"
      width="80vw"
      class="add-country-button-add-dialog"
      @close="handleClose"
    >
      <div class="select-warpper">
        <span class="add" @click="handleAdded" v-if="checkableList.length"
          >已选{{ formworkName }}： {{ getSelectedCountry(checkableList) }}</span
        >
        <span class="add" @click="handleAdded" v-else>添加{{ formworkName }}</span>
      </div>

      <FedexGoodDetail v-if="visible" ref="fedexGoodDetail" :resetData="resetData" />

      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">取 消</el-button>
        <el-button :loading="loading" type="primary" @click="handleConfirmed">确 定</el-button>
      </span>
    </el-dialog>
    <selectRegion
      ref="selectRegion"
      v-model="checkedList"
      :type="isDomestic ? 'province' : 'country'"
      :visible.sync="dialogVisible"
      :disabledList="disabledList"
    ></selectRegion>
    <paymentPassBox
      :loading.sync="loading"
      :passwordVal.sync="passwordVal"
      :payVisible.sync="payVisible"
      @commitPassword="commitPassword"
    ></paymentPassBox>
  </div>
</template>

<script>
import formworkType from '@/views/fedex/formwork/mixin/formworkType'
import { batchCreateTemplate, getCreatedTemplate } from '@/api/template'
import paymentPassBox from '@/views/components/payDialog'
import FedexGoodDetail from './fedexGoodDetail'
import selectRegion from './selectRegion'
import { passwordSetted } from '@/api/balanceApi'
import { map, uniqBy } from 'lodash'
import { mapGetters } from 'vuex'
import { approveCommit } from '@/api/auth/realNameAuthApi'

export default {
  components: {
    FedexGoodDetail,
    paymentPassBox,
    selectRegion
  },
  mixins: [formworkType],
  props: {
    templateId: { type: Number },

    sup_this: {
      type: Object
    },

    //复制时候重置data
    resetData: {
      type: Array,
      default: () => []
    },

    list: {
      type: Array
    }
  },
  data() {
    return {
      visible: false,
      dialogVisible: false,
      payVisible: false,
      passwordVal: [],
      loading: false,
      addLoading: false,
      checkedList: []
    }
  },
  computed: {
    ...mapGetters(['is_super']),
    checkableList() {
      return this.checkedList.filter((item) => !this.disabledIdList.includes(item.value))
    },
    checkableIdList() {
      return map(this.checkableList, 'value')
    },
    disabledIdList() {
      return map(this.disabledList, 'value')
    },
    disabledList() {
      return uniqBy(this.list, 'value')
    }
  },
  watch: {
    list: {
      handler() {
        this.initCheckedList()
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    showDialog() {
      this.addLoading = true
      this.visible = true
      this.initCheckedList()
    },
    async commitPassword() {
      let data = {
        countryIdList: this.checkableIdList,
        templateId: this.templateId,
        list: [{}]
      }
      if (!this.is_super) {
        const approveConfirmPassword = this.processPaymentPassword()
        if (!approveConfirmPassword) {
          return
        }
        data = Object.assign(data, { approveConfirmPassword })
      }

      this.addLoading = true
      this.loading = true

      const { fedexGoodDetail } = this.$refs
      if (!fedexGoodDetail) return
      data.list = fedexGoodDetail.data

      let res = await awaitResolve(
        batchCreateTemplate(data)
      )
      if (!res) {
        this.addLoading = false
        this.loading = false
        return
      }

      this.$message.success('创建成功')
      let rs = await awaitResolve(
        getCreatedTemplate({ isDeleted: 0, templateId: this.templateId })
      )
      this.addLoading = false
      this.loading = false
      if (!rs) return

      this.checkedList = rs.detail || []
      this.sup_this.init()
      this.payVisible = false
      this.visible = false
    },
    processPaymentPassword() {
      let pwd = ''
      this.passwordVal
        .filter((item) => {
          return item !== ''
        })
        .map((item) => {
          pwd += item
        })
      if (pwd.length !== 6) {
        this.$message.warning('请输入完整密码')
        return undefined
      } else {
        return pwd
      }
    },
    async handleConfirmed() {
      if (this.checkableIdList.length === 0) {
        this.$message.warning(`请先选择需要添加的${this.formworkName}`)
        this.loading = false
        return
      }
      try {
        if (!this.is_super) {
          const { code, detail } = await passwordSetted({})
          if (!$SUC(code)) {
            // 如果当前登录用户没有设置交易密码，则提示跳转并return
            if (detail == 0) {
              this.$message.warning('当前登录用户未设置交易密码')
            } else {
              this.passwordVal = []
              this.payVisible = true
            }
          }
        } else {
          this.commitPassword()
        }
      } catch (error) {}
    },
    handleClose() {
      this.addLoading = false
    },
    async handleAdded() {
      this.dialogVisible = true
    },
    getSelectedCountry(data) {
      return map(data, 'label').join('、')
    },

    initCheckedList() {
      this.checkedList = [...this.disabledList]
    }
  }
}
</script>

<style lang="scss" scoped>
.add-country-button-component {
  display: inline-block;
  margin-left: 8px;
}
.addButton {
  padding: 0;
  width: 128px;
  height: 32px;
}
.dd-country-button-dialog-warpper {
  ::v-deep {
    .el-dialog__body {
      padding: 0;
    }
  }
}
.add-country-button-add-dialog {
  ::v-deep {
    .el-dialog__title {
      font-size: 14px;
      color: #1a1a1a;
    }
    .el-dialog__header {
      height: 52px;
      line-height: 52px;
      padding: 0 0 0 24px;
      border: 1px solid #dcdee0;
    }
  }
  .select-warpper {
    margin-top: 14px;
    font-size: 14px;
    font-weight: 500;
    color: #1a1a1a;
    .add {
      cursor: pointer;
      color: #3841db;
    }
  }
  .whole-warpper {
    display: flex;
    height: 500px;
    .left {
      width: 527px;
      border-right: 1px solid #dcdee0;
      border-bottom: 1px solid #dcdee0;
      .continent-warpper {
        height: 48px;
        align-items: center;
        border-bottom: 1px solid #dcdee0;
        display: flex;
        .active {
          color: #fff !important;
          background: #3841db !important;
          border: 1px solid #3841db !important;
          border-radius: 2px !important;
        }
        .all {
          margin-left: 16px;
        }
        .all,
        .continent-whole {
          padding: 9px;
          border: 1px solid #dcdee0;
          border-radius: 2px;
          margin-right: 12px;
          background: #f5f5f5;
          height: 32px;
          cursor: pointer;
          color: #969799;
          li {
            list-style: none;
          }
        }
      }
      .continent-select-warpper {
        padding: 16px 0 0 16px;
        height: 390px;
        overflow: auto;
        .all {
          ::v-deep {
            .el-checkbox__label {
              padding-right: 25px;
            }
          }
        }
        ::v-deep {
          .el-checkbox__inner {
            width: 16px;
            height: 16px;
            border: 1px solid #c8c9cc;
            border-radius: 2px;
          }
          .el-checkbox-group {
            display: inline;
          }
          .el-checkbox__label {
            padding-left: 5px;
          }
          .el-checkbox {
            color: #595961;
            font-weight: normal;
            margin-bottom: 13px;
          }
        }
      }
      .search-warpper {
        height: 56px;
        line-height: 56px;
        border-bottom: 1px solid #dcdee0;
        padding-left: 16px;
      }
    }
    .right {
      width: 296px;
      border-bottom: 1px solid #dcdee0;
      .cancel-all {
        color: #3841db;
        cursor: pointer;
      }
      .select {
        padding: 16px 0 0 16px;
        height: 420px;
        overflow: auto;
        .el-checkbox {
          color: #595961;
          font-weight: normal;
          margin-bottom: 13px;
        }
      }
      .search-warpper {
        display: inline-block;
        height: 56px;
        width: 100%;
        line-height: 56px;
        border-bottom: 1px solid #dcdee0;
        padding-left: 16px;
        color: #1a1a1a;
      }
    }
  }
}
</style>
